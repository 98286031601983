.loginBackground {
    background-color: #112a44;
    background: url('../../../public/assets/login-page-blank.jpg') no-repeat center center fixed;
    background-size: cover;
}
.loginform{
    display:flex;
    flex-direction:column;
    margin: 20px 10px 0px 0px;
    width: 40ch;
}


@media screen and ( max-width: 1200px){
    .loginform {
        width: 40ch
    }
}

@media screen and ( max-width: 1024px){
    .loginform {
        width: 30ch
    }
}
@media screen and ( max-width: 900px){
    .loginform {
        width: 100%
    }
}

