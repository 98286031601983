.typo{
    font-size: 14px !important;
    font-weight: 500 !important;
    text-align: center;
    letter-spacing: 0.1em;
}

.typoDisabled {
    color: rgba(255, 255, 255, 0.07 ) !important;
}
.chartLegendLabel {
    margin-left: 8px !important;
    text-align: left;
}
.productDetails {
    font-size: 14px !important;
    font-weight: 500 !important;
}
.productDetails_smallText{
    font-size: 12px !important;
}
.anchor {
    font-size: 11px !important;
    font-weight: 400 !important;
    color: #64ACFF !important;
}
.market {
    display:flex;
    flex-flow:column;
    width:100%;
    margin-bottom:16px;
    padding:25px 40px 10px 40px;
    justify-content:space-between;
    align-items:flex-start
}
.market-logo {
    width:100%;
    max-width:160px;
    max-height:162px;
    height:auto;
    left:0;
    top:0;
    transform:none
}
.summary {
    margin-right:16px;
    display:flex;
    justify-content:space-between;
}
.location-grid{
    margin-bottom: 3em;
    margin-top: 3em;
}
.footer-text {
    margin-top:1em;
    font-weight:400;
    font-size:11px;
    color:#FFFFFF;
    font-family:'Inter'
}

.tableText{
    font-family: 'Inter' !important;
    /* font-size: 16px !important; */
    font-size: 13px !important;
    font-weight: 500 !important;
    text-align: left;
}
.headingText {
    font-size: 15px !important;
}
.smallText{
    font-size: 10px !important;
    /* font-size: 12px !important; */
}
.normalText{
    /* font-size: 12px !important; */
    font-size: calc(0.6rem + (0.1 * ((100vw - 32rem) / 96))) !important;
}
.fontweight-500 {
    font-weight: 500 !important;
}
@media (max-width:1200px){
    .typo{
        font-size: 13px !important;
    }
    .tableText, .anchor{
        font-size: 11px !important;
    }
    /* .normalText{
        font-size: 10px !important;
    } */
    
}


@media (max-width:1024px){
    .typo{
        font-size: 12px !important;
    }
    /* .normalText{
        font-size: 8px !important;
    } */
    .tableText, .anchor{
        font-size: 9px !important;
    }
    .market{
        padding:25px 25px 10px 25px;
    }
    .market-logo{
        max-width: 120px;
        max-height: 120px;
    }
    footer-text {
        font-size: 8px;
    }
    .productDetails{
        font-size: 12px !important;
    }
}
@media (max-width:800px){
    .typo{
        font-size: 10px !important;
    }
    /* .normalText{
        font-size: 8px !important;
    } */
    .tableText, .anchor{
        font-size: 8px !important;
    }
    .market{
        padding:20px 20px 10px 20px;
    }
    .market-logo{
        max-width: 100px;
        max-height: 100px;
    }
    footer-text {
        font-size: 7px;
    }
    .productDetails{
        font-size: 10px !important;
    }
}
@media (max-width:677px){
    .typo{
        font-size: 8px !important;
    }
    .tableText, .anchor{
        font-size: 6px !important;
    }
    .market{
        padding:15px 15px 10px 15px;
    }
    .market-logo{
        max-width: 100px;
        max-height: 100px;
    }
    .footer-text {
        font-size: 6px;
    }
    .productDetails{
        font-size: 8px !important;
    }
}
