.chat-messages {
  max-height: 500px;
  min-height: 10px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #bdbdbd #f5f5f5;
}

.chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 4px;
}

.chat-message { 
  padding: 10px;
  /* background: #ded5d5; */
  background: lightgray;
  margin-bottom: 15px;
  font-size: small;
  word-break: break-word;
  width: 100%;
}

.commentleft-arrow {
  border-right: 10px solid white;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  margin-right: -3px;
  margin-top: 17px;
  height: 15px;
  width: 4px;
}

.responseRight-arrow{
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translateY(-50%);
  width: 4px;
  height: 15px;
  border-Top: 7px solid transparent;
  border-Bottom: 7px solid transparent;
  border-Left: 7px solid #d9fdd3;
}

.talk-bubble {
	margin: 40px;
  display: inline-block;
  position: relative;
	/* width: 200px; */
	height: auto;
}
.tri-right.left-top:after{
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 32px;
  right: auto;
  top: -32px;
  bottom: auto;
  border: 9px solid;
  border-color: white transparent transparent transparent;
}
.tri-right.right-top:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 0em;
  right: auto;
  top: -32px;
  bottom: auto;
  border: 13px solid;
  border-color: #d9fdd3 transparent transparent transparent;
}
