

.headerClasses{
    /* background-color: #232D3B; */
    height:70px;
    width: auto !important;
    max-width: 100% !important;
    padding: 10px 24px;
    /* display: flex; */
}
.logo{
    width: auto;
    max-width: 100%;
}
.navContainer {
    padding:0px 70px !important;
    display: flex;
    justify-content: flex-start;
    width: 71%;
    margin-top: 12px;
}
.nav{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.navLink{
    font-family: 'Inter';
    font-size: 11px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    padding-bottom: 20px;
    border-bottom: 4px solid rgba(85, 158, 255, 0) !important;
}
.darknavLink{
    color: #E4E3E3;
}
.lightnavLink {
    color: #5A5A5A;
}
.navContainer .navLink:not(:last-child)  {
    margin-right: 2em;
}
.nav img {
    margin-right: 5px;
}
.dark-dashboard {
    margin-top: 5px;
    padding-left: 25px;
    background: url('../../../public/assets/darkdashboard-icon.png') no-repeat top left;
}
.dark-dashboard:hover, .dark-dashboard.active {
    background: url('../../../public/assets/darkdashboard-selected-icon.png') no-repeat top left;
}

.dark-myproduct {
    margin-top: 0px;
    padding-left: 30px;
    background: url('../../../public/assets/darkmyproduct-icon.png') no-repeat top left;
}
.dark-myproduct:hover, .dark-myproduct.active {
    background: url('../../../public/assets/darkmyproduct-selected-icon.png') no-repeat top left;
}

.dark-addproduct {
    margin-top: 0px;
    padding-left: 30px;
    background: url('../../../public/assets/darkproductadd-icon.png') no-repeat top left;
}
.dark-addproduct:hover, .dark-addproduct.active {
    background: url('../../../public/assets/darkproductadd-selected-icon.png') no-repeat top left;
}

.dark-report {
    margin-top: 0px;
    padding-left: 28px;
    background: url('../../../public/assets/darkreport-icon.png') no-repeat top left;
}
.dark-report:hover, .dark-report.active {
    background: url('../../../public/assets/darkreport-selected-icon.png') no-repeat top left;
}

.dark-contact {
    margin-top: 0px;
    padding-left: 28px;
    background: url('../../../public/assets/darkcontact-icon.png') no-repeat top left;
}
.dark-contact:hover, .dark-contact.active {
    background: url('../../../public/assets/darkcontact-selected-icon.png') no-repeat top left;
}

.light-dashboard {
    margin-top: 5px;
    padding-left: 25px;
    background: url('../../../public/assets/lightdashboard-icon.png') no-repeat top left;
}
.light-dashboard:hover, .light-dashboard.active {
    background: url('../../../public/assets/lightdashboard-selected-icon.png') no-repeat top left;
}

.light-myproduct {
    margin-top: 0px;
    padding-left: 30px;
    background: url('../../../public/assets/lightmyproduct-icon.png') no-repeat top left;
}
.light-myproduct:hover, .light-myproduct.active {
    background: url('../../../public/assets/lightmyproduct-selected-icon.png') no-repeat top left;
}

.light-addproduct {
    margin-top: 0px;
    padding-left: 30px;
    background: url('../../../public/assets/lightproductadd-icon.png') no-repeat top left;
}
.light-addproduct:hover {
    background: url('../../../public/assets/lightproductadd-selected-icon.png') no-repeat top left;
}

.light-report {
    margin-top: 0px;
    padding-left: 28px;
    background: url('../../../public/assets/lightreport-icon.png') no-repeat top left;
}
.light-report:hover, .light-report.active {
    background: url('../../../public/assets/lightreport-selected-icon.png') no-repeat top left;
}

.light-contact {
    margin-top: 0px;
    padding-left: 28px;
    background: url('../../../public/assets/lightcontact-icon.png') no-repeat top left;
}
.light-contact:hover {
    background: url('../../../public/assets/lightcontact-selected-icon.png') no-repeat top left;
}


.lightnavLink:hover, .lightnavLink:active {
    text-decoration: none;
    border-bottom: 4px solid rgba(85, 158, 255, 1) !important;
}
.lightnavLink.active {
    color: #0075B2;
}

.darknavLink:hover, .darknavLink:active  {
    text-decoration: none;
    color: #559EFF;
    border-bottom: 4px solid rgba(85, 158, 255, 1) !important;
}
.darknavLink.active{
    color: #559EFF;
}

.rightNav{
    display:flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 15%;
    margin-top: 6px;
}
.headerAvatar {
    padding-right: 0px;
}
.headerAvatar img{
    margin-right: 0px;
    object-fit: contain;
}

@media ( max-width: 1200px) {
    .navLink {
        font-size: 9px !important;
    }
    .navContainer{
        width: 85%;
    }
}
@media (max-width: 1024px){
    .headerClasses{
        padding: 10px 12px;
        height: 50px;
    }
    .logo{
        max-width: 90%;
    }
    .navContainer{
        padding:0px 50px !important;
        margin-top:-10px;
    }
    .navLink {
        font-size: 7px !important;
    }
}
